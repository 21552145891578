import { tw } from '@/utils/tw';
import { default as BaseTagTheme } from 'base/components/Tag/theme';

const Tag = tw.theme({
  extend: BaseTagTheme,
  base: 'text-headline-3xs',
  variants: {
    colors: {
      default: `border-transparent bg-gray-100 text-black ring-royal-blue-200 hover:bg-gray-200 focus:bg-gray-300 active:bg-gray-300`,
      text: `text-primary-700`,
      alternative: 'text-gray-600',
    },
    size: {
      small: `px-3 py-1.5`,
      medium: `px-3 py-2.5`,
      large: `px-6 py-4`,
      text: `text-headline-2xs`,
      video: 'mb-2 text-headline-3xs',
    },
    variant: {
      text: 'capitalize',
    },
  },
});

export default Tag;
